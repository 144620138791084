<template>
	<ValidationForm
		id="manage_channel_statement_container"
		#default="{ handleSubmit }"
		style="width: 100%; overflow-y: auto"
		tag="div"
	>
		<ChannelsLayout
			v-if="channel && !showStatementTranslationScreen"
		>
			<template
				#content
				class="cassie-vertical-md"
			>
				<SectionCard class="mt-4">
					<template #title>
						{{ 'Channel Translation' | useLabels }}
					</template>
					<template #body>
						<v-row dense>
							<v-col :cols="6">
								<TextField
									:value="channel.languageName"
									label="Language"
									disabled
								/>
							</v-col>
							<v-col :cols="6">
								<TextField
									:value="channel.channelName"
									:label="'Default Channel Name' | useLabels"
									disabled
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col :cols="6">
								<TextField
									v-model="translatedChannelName"
									:label="'Translated Channel Name *' | useLabels"
									rules="required"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						{{ 'Statement Translations' | useLabels }}
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="statementTranslations"
							hide-default-footer
							@click:row="onEditStatementTranslation"
						>
							<template #item.statementText="{ item }">
								{{ item.statementText ? getTruncatedText(item.statementText, 50) : null }}
							</template>
							<template #item.liveTranslatedStatementText="{ item }">
								{{ item.liveTranslatedStatementText ? getTruncatedText(item.liveTranslatedStatementText, 50) : null }}
							</template>
							<template #item.action="{ item }">
								<IconButton
									v-if="canCreateApproveOrPublishDraftStatements && !universalChannelNotUser"
									:tooltip-text="'Edit Statement Translation' | useLabels"
									@click="onEditStatementTranslation(item)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									v-else
									:tooltip-text="'View Statement Translation' | useLabels"
									@click="onEditStatementTranslation(item)"
								>
									mdi-eye
								</IconButton>
								<IconButton
									v-if="(item.liveTranslatedStatementText || item.draftTranslatedStatementText) && !universalChannelNotUser && canDeleteStatements"
									:tooltip-text="'Delete Statement Translation' | useLabels"
									@click.stop.prevent="onRemoveStatementTranslation(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<ConfirmDeleteModal
					v-if="statementTranslationToBeRemoved"
					:entity-name="statementTranslationToBeRemoved.statementText"
					entity-type="Statement Translation"
					@close="statementTranslationToBeRemoved = null"
					@delete="deleteStatementTranslation"
				/>
				<ManageStatementTranslationModal
					v-if="statementTranslationToEdit"
					:statement-translation-to-edit="statementTranslationToEdit"
					@close="statementTranslationToEdit = null"
					@save="editStatementTranslation"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="!universalChannelNotUser"
							@click="handleSubmit(saveChannelTranslationChanges)"
						>
							Save
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ChannelsLayout>
		<ManageStatementTranslation
			v-else
			:statement-translation-to-edit="statementTranslationToEdit"
			:channel-name="channel.channelName"
			:language-name="channel.languageName"
			accessed-via="channel"
			@load-channel-statements="loadStatements"
			@close="() => {
				statementTranslationToEdit = null,
				showStatementTranslationScreen = false
			}"
		/>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../shared/utils/date-formatting.js'
import { CHANNELS } from '../../../router/route-names.js'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import ChannelsLayout from './channels-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { updateStatement, upsertChannelTranslation } from '../../../../../shared/utils/api/channels.js'
import { deleteStatementTranslation, getStatements } from '../../../../../shared/utils/api/statements.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import ManageStatementTranslation from '../statements/manage-statement/_manage-statement-translation.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import { STATEMENT_STATUS } from '../statements/statement-enums.js'
import { showStatementDrafts } from '../../../../../shared/state/config-keys.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { CAN_DELETE_STATEMENTS, CAN_CREATE_DRAFT_STATEMENTS, CAN_APPROVE_DRAFT_STATEMENTS, CAN_PUBLISH_DRAFT_STATEMENTS, CHANNELS_FULL_PERMISSIONS, COMPONENTS_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'

export default {
	components: {
		ConfirmDeleteModal,
		TextField,
		DataTable,
		SectionCard,
		ChannelsLayout,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		IconButton,
		ManageStatementTranslation,
		ValidationForm
	},
	props: {
		channelToEdit: {
			type: Object,
			required: true
		}
	},
	setup () {
		return { showStatementDrafts, getTruncatedText }
	},
	data () {
		return {
			channel: JSON.parse(JSON.stringify(this.channelToEdit)),
			translatedChannelName: null,
			statementTranslationToEdit: null,
			showStatementTranslationScreen: false,
			statementTranslationToBeRemoved: null,
			allStatements: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		channelsFullPermissions () {
			return this.productAreaPermission(CHANNELS_FULL_PERMISSIONS) || this.productAreaPermission(COMPONENTS_MODULE_FULL_PERMISSIONS)
		},
		statementTranslations () {
			return this.channelStatements?.map(statement => {
				const liveTranslatedStatementText = statement.liveStatements?.find(({ languageId }) => languageId === this.channel.languageId)?.statementText
				const draftTranslatedStatementText = statement.draftStatements?.find(({ languageId }) => languageId === this.channel.languageId)?.statementText
				const translationSvid = statement.draftStatements?.find(({ languageId }) => languageId === this.channel.languageId)?.svid
				const selectedLanguageDraftStatement = statement.draftStatements?.find(({ languageId }) => languageId === this.channel.languageId)
				const liveStatusText = liveTranslatedStatementText ? 'Published' : ''
				const draftStatusText = selectedLanguageDraftStatement ? this.getDraftStatus(selectedLanguageDraftStatement.draftState, selectedLanguageDraftStatement.queuedActionDateTime) : ''
				const separatorText = liveStatusText.length > 0 && draftStatusText.length > 0 ? ', ' : ''
				return {
					...statement,
					languageName: this.channel.languageName,
					languageId: this.channel.languageId,
					liveTranslatedStatementText: liveTranslatedStatementText ?? null,
					draftTranslatedStatementText: draftTranslatedStatementText ?? null,
					translationSvid: translationSvid ?? null,
					selectedLanguageDraftStatement: selectedLanguageDraftStatement ?? null,
					selectedLanguageDraftStatus: liveStatusText + separatorText + draftStatusText,
					brandID: this.channel.brandID
				}
			})
		},
		channelStatements () {
			return this.allStatements.filter(({ channelID }) => channelID === this.channel.channelId)
		},
		tableHeaders () {
			let headers = [
				{ value: 'statementID', text: useLabels('Statement ID'), width: '10%' },
				{ value: 'statementText', text: useLabels('Live Default Statement Text'), width: '30%' },
				{ value: 'liveTranslatedStatementText', text: useLabels('Live Translated Statement Text'), width: '30%' },
				{ value: 'selectedLanguageDraftStatus', text: 'Status', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
			if (showStatementDrafts.value === false) {
				headers = headers.filter(({ value }) => value !== 'selectedLanguageDraftStatus')
			}
			return headers
		},
		universalChannelNotUser () {
			return !this.userHasUniversalBrand && this.channelToEdit.brandID === 0
		},
		canCreateApproveOrPublishDraftStatements () {
			if (showStatementDrafts.value === false) {
				return false
			}
			return (this.productAreaPermission(CAN_PUBLISH_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_APPROVE_DRAFT_STATEMENTS))
		},
		canDeleteStatements () {
			return this.productAreaPermission(CAN_DELETE_STATEMENTS)
		}
	},
	created () {
		if (!this.channel) {
			this.navigateToOverview()
		} else {
			this.translatedChannelName = this.channel.translatedChannelName
			this.loadStatements()
		}
	},
	methods: {
		async loadStatements () {
			const { data: statements } = await getStatements()
			this.allStatements = statements
		},
		async editStatementTranslation (statementTranslation) {
			await updateStatement(statementTranslation)
			showSnackbar(useLabels('This statement translation has been updated'))
			const index = this.channel.statements.findIndex(({ statementID }) => statementID === statementTranslation.statementID)
			this.$set(this.channel.statements, index, statementTranslation)
		},
		async deleteStatementTranslation () {
			const translationToBeRemoved = {
				statementId: this.statementTranslationToBeRemoved.statementId,
				languageId: this.statementTranslationToBeRemoved.languageId
			}
			await deleteStatementTranslation(translationToBeRemoved)
			showSnackbar(useLabels('This channel translation has been removed'))
			this.loadStatements()
			this.statementTranslationToBeRemoved = null
		},
		onEditStatementTranslation (statementTranslation) {
			this.statementTranslationToEdit = statementTranslation
			this.showStatementTranslationScreen = true
		},
		navigateToOverview () {
			this.$router.push({
				name: CHANNELS,
				params: {
					previouslySelectedLanguage: this.channel.languageId
				}
			})
		},
		async saveChannelTranslationChanges () {
			const channelTranslation = {
				channelTranslationId: this.channel.channelTranslationID ?? null,
				channelId: this.channel.channelId,
				languageId: this.channel.languageId,
				channelText: this.translatedChannelName
			}
			await upsertChannelTranslation(channelTranslation)
			showSnackbar(useLabels('This channel name has been updated with a new translation'))
			this.navigateToOverview()
		},
		onRemoveStatementTranslation (statementTranslation) {
			this.statementTranslationToBeRemoved = statementTranslation
		},
		getDraftStatus (statusCode, queuedActionDateTime) {
			switch (statusCode) {
				case STATEMENT_STATUS.NEW:
					return 'Draft: New'
				case STATEMENT_STATUS.SUBMITTED:
					return 'Draft: Submitted'
				case STATEMENT_STATUS.APPROVED:
					return 'Draft: Approved'
				case STATEMENT_STATUS.AWAITING_PUBLISH:
					return 'Draft: Live on ' + format(new Date(queuedActionDateTime), DATE_TIME_FORMAT_NO_SECONDS)
				default:
					return ''
			}
		}
	}
}
</script>
